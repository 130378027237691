import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styles from './SendForm.module.css';
import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

function SendForm() {
  const [state, handleSubmit] = useForm('mgebenjr');
  if (state.succeeded) {
    return (
      <p style={{ fontSize: '22px', fontWeight: '500' }}>
        Cпасибо, с вами скоро свяжутся!
      </p>
    );
  }
  return (
    <form
      className={styles.sendForm}
      onSubmit={handleSubmit}
    >
      <div className={styles.title}>
        Заполните данные для обратной связи и мы свяжемся <br />с вами в
        ближайшее время
      </div>
      <div className={styles.data}>
        <img
          className={styles.pic}
          src="https://i.postimg.cc/zXP6xn5M/send.png"
          alt=""
        />
        <div className={styles.dataPic}>
          <EmailIcon style={{ fontSize: '30px', color: 'white' }} />
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Email"
          />
        </div>
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        <div className={styles.dataPic}>
          <ContactPhoneIcon style={{ fontSize: '30px', color: 'white' }} />
          <input
            id="TelNumber"
            type="TelNumber"
            name="TelNumber"
            placeholder="Номер телефона"
          />
        </div>
        <ValidationError
          prefix="TelNumber"
          field="telNumber"
          errors={state.errors}
        />
        <button
          className={styles.sendButt}
          type="submit"
          disabled={state.submitting}
        >
          Отправить
        </button>
      </div>
    </form>
  );
}

export default SendForm;
