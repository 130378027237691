import React from 'react';
import styles from './Banner.module.css';

const Banner = () => {
  var Scroll = require('react-scroll');
  var scroll = Scroll.animateScroll;

  const scrollHandler = () => {
    scroll.scrollToBottom();
  };

  return (
    <div className={styles.banner}>
      <div className={styles.slogan}>
        Корпоративный отдых
        <br />
        Детские программы <br />Спортивные сборы
      </div>
      <div className={styles.subs}>
        В самом центре Тюмени, на главной улице города, находится офис
        туристической компании «Ника». <br />
        <br />
        ООО «Ника» включена в Единый федеральный реестр туроператоров России, на
        рынке туристических услуг работает более 10 лет.
      </div>
      <div className={styles.pic}>
        <img
          className={styles.picGirl}
          src="https://i.postimg.cc/jqZqrbgj/image.jpg"
          alt=""
        />
      </div>

      <div
        onClick={() => scrollHandler()}
        className={styles.writeButton}
      >
        Оставить заявку
      </div>
    </div>
  );
};

export default Banner;
