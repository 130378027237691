import './App.css';
import MainPage from './pages/mainPage/MainPage';
import Navbar from './components/navbar/Navbar';
import { Routes, Route} from 'react-router-dom';
import Travel from './pages/travel/Travel';
import Contact from './pages/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="App">
      <div className="mask"></div>
      <Navbar />
      <Routes>
        <Route
          path={`/`}
          element={<MainPage />}
        />
        <Route
          path={`/travel`}
          element={<Travel />}
        />
        <Route
          path={`/contact`}
          element={<Contact />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
