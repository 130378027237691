import React from 'react';
import styles from './Navbar.module.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.navbar}>
      <div className={styles.navMenu}>
        <div className={styles.logoTempl}>
          <div
            className={styles.logo}
            onClick={() => navigate(`/`)}
          ></div>
          <div
            className={styles.titleName}
            onClick={() => navigate(`/`)}
          >
            Туроператор ООО «Ника»
          </div>
        </div>
        <div className={styles.template}>
          <div
            onClick={() => navigate(`/travel`)}
            className={styles.navItem}
          >
            Деятельность
          </div>
          <div
            onClick={() => navigate(`/contact`)}
            className={styles.contact}
          >
            Контакты и информация
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
