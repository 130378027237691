import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from './YaMap.module.css';

const YaMap = () => {
  const defaultState = {
    center: [57.150207, 65.547075],
    zoom: 15,
  };
  return (
    <YMaps>
      <Map
        className={styles.yaMap}
        defaultState={defaultState}
      >
        <Placemark geometry={[57.150207, 65.547075]} />
      </Map>
    </YMaps>
  );
};

export default YaMap;
