import React from 'react';
import styles from './Travel.module.css';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TrySharpIcon from '@mui/icons-material/TrySharp';

const Travel = () => {
  return (
    <div className={styles.travel}>
      <div className={styles.title}>Основные виды деятельности</div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/strength.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>
            Организация учебно - тренировочных сборов для юных спортсменов
          </div>
        </div>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/child-safe-zone.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>Летний оздоровительный отдых детей</div>
        </div>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/backpack.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>
            Экскурсионные программы для школьников
          </div>
        </div>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/family.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>
            Организация семейного отдыха для сотрудников предприятий
          </div>
        </div>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/training.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>
            Организация и проведение семинаров, конференций, корпоративных
            мероприятий
          </div>
        </div>
        <div className={styles.card}>
          <div>
            <img
              className={styles.picGirl}
              src="https://img.icons8.com/bubbles/200/null/apple-health.png"
              alt=""
            />
          </div>
          <div className={styles.subs}>Санаторно - курортное лечение</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.wrapperItem1}>
          <PriorityHighIcon
            style={{
              padding: '0px 5px 0px 0px',
              fontSize: '25px',
              color: 'black',
            }}
          />
          Благодаря нашему многолетнему опыту мы можем предоставить услуги
          по наиболее выгодной цене.
        </div>
        <div className={styles.wrapperItem2}>
          <TrySharpIcon
            style={{
              padding: '0px 12px 0px 0px',
              fontSize: '25px',
              color: '#ff8720',
            }}
          />
          Компания нацелена на долгосрочное и взаимовыгодное сотрудничество.
          Большинство наших клиентов работают с нами на постоянной основе.
        </div>
      </div>
    </div>
  );
};

export default Travel;
