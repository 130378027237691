import React from 'react';
import styles from './Title.module.css';

const Title = () => {
  return (
    <div className={styles.title}>
      Преимущества наших программ
      <img
        className={styles.pic}
        src="https://i.postimg.cc/hPYWCrv7/123.png"
        alt=""
      />
    </div>
  );
};

export default Title;
