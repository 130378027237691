import React from 'react';
import Banner from '../../components/banner/Banner';
import Disc from '../../components/disc/Disc';
import SendForm from '../../components/sendForm/SendForm';
import Title from '../../components/title/Title';
import styles from './MainPage.module.css';

const MainPage = () => {
  return (
    <div className={styles.mainPage}>
      <Banner />
      <Title />
      <Disc />
      <SendForm />
    </div>
  );
};

export default MainPage;
