import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footerMain}>
      <div className={styles.footer}>
        <div className={styles.Logo}>
          <img
            className={styles.pic}
            src="https://i.postimg.cc/K8rPSHmH/logo-Black.png"
            alt=""
          />
          <div className={styles.name}>
            Туроператор <br /> ООО «Ника»
          </div>
        </div>
        <div>
          <div>
            <a href="">
              <img
                className={styles.picContact}
                src="https://i.postimg.cc/15mxsdFN/111.png"
                alt=""
              />
            </a>
            <a href="">
              <img
                className={styles.picContact}
                src="https://i.postimg.cc/XvymsSRt/222.png"
                alt=""
              />
            </a>
            <a href="">
              <img
                className={styles.picContact}
                src="https://i.postimg.cc/zG4Pb2y7/333.png"
                alt=""
              />
            </a>
          </div>
          <div className={styles.copy}>
            © Все права защищены ООО «Ника» 2023
          </div>
        </div>
        <div className={styles.ad}>
          г. Тюмень
          <br /> ул. Республики д.55
          <br /> офис 504
        </div>
      </div>
    </div>
  );
};

export default Footer;
