import React from 'react';
import styles from './Disc.module.css';

const Disc = () => {
  return (
    <div className={styles.cards}>
      <div className={styles.card1}>
        <img
          className={styles.picGirl}
          src="https://i.postimg.cc/JzLBvbGP/1.png"
          alt=""
        />
        <div className={styles.title}>
          Профессионализм и <br />
          компетентность
        </div>
        <div className={styles.cardDisc1}>
          Все сотрудники - квалифицированные специалисты с высшим образованием и
          большим опытом работы в сфере туризма.
        </div>
      </div>
      <div className={styles.card1}>
        <img
          className={styles.picGirl}
          src="https://i.postimg.cc/prRg3cMP/2.png"
          alt=""
        />
        <div className={styles.title}>
          Опыт на <br />
          рынке
        </div>
        <div className={styles.cardDisc1}>
          Компания работает как самостоятельный туроператор по России с августа
          2011 года. Имеет прямые договоры с поставщиками услуг, самостоятельно
          организует транспортную схему.
          <br />
          Большой опыт работы с корпоративными клиентами, предприятиями и
          организациями, профсоюзными комитетами.
        </div>
      </div>
      <div className={styles.card1}>
        <img
          className={styles.picGirl2}
          src="https://i.postimg.cc/1t7hrHKy/5.png"
          alt=""
        />
        <div className={styles.title}>
          Соотношение цены
          <br /> и качества
        </div>
        <div className={styles.cardDisc1}>
          Оптимальное соотношение цены и качества, достижение высокого уровня
          комфорта и сервиса при минимальных затратах.
        </div>
      </div>
      <div className={styles.card1}>
        <img
          className={styles.picGirl}
          src="https://i.postimg.cc/dVLzMTpZ/4.png"
          alt=""
        />
        <div className={styles.title}>
          Инновационные
          <br /> методики
        </div>

        <div className={styles.cardDisc1}>
          Передовые педагогические программы и методики в работе с детскими
          группами, успешный практический опыт организации воспитательной работы
          с детьми, подростками и молодежью на отдыхе и во время экскурсионных
          туров, проведения культурно-массовых мероприятий и спортивных сборов.
        </div>
      </div>
    </div>
  );
};

export default Disc;
