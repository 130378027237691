import React from 'react';
import YaMap from './YaMap';
import styles from './Contact.module.css';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import cert from '../../assets/cert.jpg';

const Contact = () => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'rgb(230 230 230)',
    border: '2px solid rgb(24 24 24)',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={styles.contact}>
      <div className={styles.contactInfo}>
        <div className={styles.items}>
          <div className={styles.title}>Контактная информация</div>
          <div className={styles.box1}>
            <HomeIcon style={{ paddingRight: '7px', marginLeft: '10px' }} />{' '}
            62500 · г. Тюмень · ул. Республики · д.55 · офис 504
          </div>
          <div className={styles.box2}>
            <LocalPhoneIcon
              style={{ paddingRight: '7px', marginLeft: '10px' }}
            />{' '}
            +7 919 940-06-56 · +7 919932-42-10 · +7 922 265-92-48
          </div>
          <div className={styles.box3}>
            <AlternateEmailIcon
              style={{ paddingRight: '7px', marginLeft: '10px' }}
            />
            nika-com@bk.ru
          </div>
        </div>
        <div className={styles.title}>Юридическая информация</div>
        <div className={styles.ur}>
          Полное наименование: Общество с ограниченной ответственностью «Ника».
          <br /> Сфера туроператорской деятельности: внутренний, международный
          въездной туризм. <br />
          Свидетельство о внесении сведений о туроператоре в единый федеральный
          реестр туроператоров № РТО 009528. <br />
        </div>
        <div className={styles.ur}>
          ИНН: 7203267274 · 720301001
          <br /> ОГРН: 1117232033038 <br />
        </div>
        <div className={styles.ur2}>
          Договор страхования гражданской ответственности туроператора №
          5819/22-49 от 10.08.2022 г. <br />
          Срок действия финансового обеспечения: с «26» октября 2022 года по
          «25» октября 2023 года. <br />
          Страховщик: АО «Страховая компания Гайде».
          <div
            onClick={handleOpen}
            className={styles.buttonCert}
          >
            Открыть свидетельство
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box sx={style}>
                <div>
                  <img
                    className={styles.cert}
                    src={cert}
                    alt="Свидетельство"
                  />
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
        <div className={styles.title2}>Местоположение</div>
        <div>
          <YaMap />
        </div>
      </div>
    </div>
  );
};

export default Contact;
